<template lang="pug">
v-card.border-g-e8(flat)
    v-card-text.py-2
        .d-flex.align-center.flex-wrap.mb-1
            .text-h6.font-weight-bold {{$t('labels.order_details')}}
            edit-order-modal.ml-auto(v-if="!forCompany" :model="model")

        meal-row(
            v-for="(meal, index) in model.meals"
            :meal="meal"
            :currency="model.currency"
            :locale="model.currencyLocale"
            :key="`meal-${index}`")
        v-divider.my-2
        .d-flex.justify-space-between.text-body-1.g-26--text
            span {{$t('orders.subtotal')}}
            span {{ model.priceSumItems| currency(model.currency,model.currencyLocale) }}
        .d-flex.justify-space-between.text-body-1.g-26--text
            span {{$t('labels.delivery_fee')}}
            span {{ model.priceDelivery | currency(model.currency, model.currencyLocale) }}
        .d-flex.justify-space-between.text-body-1.g-26--text(v-if="model.companyServiceFee && model.companyServiceFee != 0")
            span {{$t('labels.service_fee')}}
            span {{ model.companyServiceFee | currency(model.currency, model.currencyLocale) }}
        .d-flex.justify-space-between.text-body-1.g-26--text(v-if="model.firmDiscount")
            span {{$t('labels.discount')}}
            span -{{ model.firmDiscount | currency(model.currency, model.currencyLocale) }}
        .d-flex.justify-space-between.text-body-1.g-26--text(v-if="model.priceCouponDiscount")
            span {{$t('labels.coupon_discount')}}
            span -{{ model.priceCouponDiscount | currency(model.currency, model.currencyLocale) }}
        .d-flex.justify-space-between.text-body-1.g-26--text.font-weight-medium
            span {{$t('labels.total')}}
            span {{ model.priceSum | currency(model.currency, model.currencyLocale) }}
        v-divider.my-2
        .text-body-1.g-26--text.font-weight-medium.mb-2 {{$t('labels.payment')}}
        .d-flex.justify-space-between.text-body-1.g-26--text(v-if="model.priceSumFirm > 0")
            span {{$t('labels.paid_allowance')}}
            span {{ model.priceSumFirm | currency(model.currency, model.currencyLocale) }}
        template(v-if="model.priceSumUser > 0")
            .d-flex.justify-space-between.text-body-1.g-26--text(v-if="model.paymentMethod")
                span {{ $t('orders.paid_by_method', {method: $t(`enums.payment_methods.${model.paymentMethod}`)}) }}
                span {{ model.priceSumUser | currency(model.currency, model.currencyLocale) }}
            template(v-else-if="canteen")
                .d-flex.justify-space-between.text-body-1.g-26--text(
                    v-for="(payment, index) in canteen.paymentSummary"
                    v-if="payment.paymentMethod"
                    :key="`canteenPayment-${index}`")
                    span(v-if="payment.paymentMethod") {{ $t('orders.paid_by_method', {method: $t(`enums.payment_methods.${payment.paymentMethod}`)}) }}
                    span(v-if="payment.paymentMethod") {{ payment.priceSumUser | currency(model.currency, model.currencyLocale) }}
        div(v-if="!forCompany")
            .d-flex.align-center.text-body-1.g-26--text.mt-4(v-if="model.costCode")
                v-icon.mr-1(color="g-26" dense) receipt
                | {{$t('labels.expense_code')}}: {{ model.costCode }}
            v-divider.my-2
            h3 {{$t('labels.order_status')}}:
            .d-flex.align-center
                small {{$t('labels.last_modified')}}: {{ this.orderLastModified.user }} at {{ this.orderLastModified.createdAt }}
            .d-flex.align-center(v-if="!isMultiCutoff")
                v-tooltip(v-if="cancelReason" bottom)
                    template(v-slot:activator="{ on }")
                        h3.text-truncate(v-on="on") {{$t(`enums.order_status.${status}`)}} - {{cancelReason}}
                    span {{cancelReason}}
                h3(v-else) {{$t(`enums.order_status.${status}`)}}
                .d-flex.ml-auto
                    edit-button(v-if="status.startsWith('cancelled') && isDeliverect" :tooltip="$t('actions.order.cancel_deliverect')" icon='cancel_schedule_send' @execute="deliverectCancel")
                    edit-button(v-if="model.status !== 'approved'" :tooltip="$t('labels.approve_order')" icon='check_circle' @execute="confirmOrderModal")
                    edit-button.ml-2(v-if="model.status !== 'cancelled' && model.status !== 'cancelled-after-approval'" :tooltip="$t('labels.cancel_order')" icon='cancel' @execute="orderCancelConfirm = true")
                    reorder-modal.ml-2(v-if="!orderIsCanteen" :order="model" :tooltip="$t('labels.reorder')" target icon)
                        v-icon replay_circle_filled
            div(v-else)
                div(v-for="(detail, index) in multicutoffDetails" :key="detail.nr")
                    .d-flex.align-center
                        h4 {{ $t('orders.multi_cutoff') }} {{ detail.nr }}. ({{ detail.cutoff }})
                            v-chip.ml-2(label x-small :color="detail.confirmed ? 'success' : 'info'") {{ $t(detail.confirmed ? 'orders.confirmed' : 'enums.order_status.new') }}
                        .d-flex.ml-auto
                            edit-button(v-if="(index === 0 && model.status !== 'approved') || !detail.confirmed" :tooltip="$t('labels.approve_order')" icon='check_circle' small @execute="confirmOrderModal(detail.cutoffTime)")
                            edit-button.ml-2(v-if="index === 0 && model.status !== 'cancelled' && model.status !== 'cancelled-after-approval'" :tooltip="$t('labels.cancel_order')" icon='cancel' small @execute="orderCancelConfirm = true")
                    small {{ $t('orders.suborders') }}: {{ detail.count }}/{{ detail.limit }}

            strong-confirm-modal(
                v-model="strongConfirm"
                :title="strong.title"
                :text="strong.text"
                :approve-color="strong.approveColor"
                @onApprove="strongAction"
                @onCancel="strongConfirm = false")
            order-cancel-modal(
                v-model="orderCancelConfirm"
                :order-id="model.id"
                :has-firm-payment="model.priceSumFirm !== '0.00'"
                @order-cancelled="onCancelOrder")

        slot
</template>

<script>
import { mapGetters } from 'vuex'
import baseFormType from '@/store/type/baseFormType'
import canteenType from '@/store/type/canteenType'
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import MealRow from '@/components/pages/orders/tabs/general/MealRow.vue'
import EditOrderModal from '@/components/pages/orders/tabs/general/dialog/EditOrderModal.vue'
import EditButton from '@/components/table/buttons/EditButton.vue'
import ReorderModal from '@/components/pages/orders/tabs/general/dialog/ReorderModal.vue'
import StrongConfirmModal from '@/components/confirm/StrongConfirmModal.vue'
import OrderCancelModal from '../../OrderCancelModal.vue'
import { getId } from '@/services/IdFromIri'
import {
    multicutoffDetails,
    isMultiCutoffBaseOrder,
} from '@/services/Orders/CanteenMultiCutoffRuleLogic'
import Notification from '@/services/Notification/Notification'

const modalBase = {
    title: '',
    text: '',
    action: '',
    approveColor: null,
}

export default {
    components: {
        EditOrderModal,
        MealRow,
        EditButton,
        ReorderModal,
        StrongConfirmModal,
        OrderCancelModal,
    },
    props: {
        model: Object,
        forCompany: Boolean,
    },
    data() {
        return {
            orderLastModified: {},
            strongConfirm: false,
            strong: { ...modalBase },
            orderCancelConfirm: false,
            confirmCutoff: null,
        }
    },
    computed: {
        ...mapGetters({
            canteen: canteenType.getters.CANTEEN,
        }),
        status() {
            return this.model?.status || 'init-payment'
        },
        orderIsCanteen() {
            return this.model.cloudcanteen || this.model.residentialcanteen
        },
        cancelReason() {
            let str = null
            const reasonType = this.model?.adminCancelReasonType
            if (reasonType) {
                str = this.$t(`enums.order_cancel_reasons.${reasonType}`)
                if (this.model?.adminCancelReason?.length) {
                    str += ': ' + this.model.adminCancelReason
                }
            }
            return str
        },
        cappedCancelReason() {
            let reason = this.cancelReason
            if (reason) {
                reason = ' - ' + reason.substring(0, 18)
            }
            return reason
        },
        isMultiCutoff() {
            return isMultiCutoffBaseOrder(this.model)
        },
        multicutoffDetails() {
            return multicutoffDetails(this.model)
        },
        isDeliverect() {
            return !!this.model?.softwareSendings
                ?.filter((el) => el.software === 'deliverect')
                .pop()
        },
    },
    watch: {
        'model.id': {
            handler() {
                if (!this.forCompany) {
                    this.getLastModifyInfo()
                }
                this.getCanteen()
            },
        },
    },
    methods: {
        strongAction() {
            this[this.strong.action]().then(() => {
                this.strongConfirm = false
            })
        },

        confirmOrderModal(cutoff) {
            // complicated a bit to send cutoff confirmation to every canteen order
            this.confirmCutoff =
                cutoff ||
                (this.orderIsCanteen
                    ? this.$date(
                          this.model.cloudcanteen?.cutoffTime ||
                              this.model.residentialcanteen?.cutoffTime
                      ).format('YYYY-MM-DD HH:mm:ss')
                    : null)
            this.strong = {
                ...modalBase,
                title: this.$t('labels.approve_order'),
                text: this.$t(
                    'orders.do_you_really_want_to_approve_this_order',
                    { orderIdentifier: this.model.identifier }
                ),
                action: 'confirmOrder',
            }
            this.strongConfirm = true
        },

        async confirmOrder() {
            if (this.model?.status !== 'approved') {
                await RestApiCollection.get(RestApiType.ORDERS).approve(
                    this.model.id
                )
            }

            if (this.confirmCutoff) {
                await RestApiCollection.get(
                    RestApiType.RESTAURANTS
                ).sendCanteenNotiConfirm(
                    getId(this.model.restaurant),
                    this.model?.cloudcanteen?.identifier ||
                        this.model?.residentialcanteen?.identifier,
                    this.confirmCutoff
                )
                this.confirmCutoff = null
            }

            this.$store.dispatch(`baseForm/${baseFormType.actions.LOAD}`, {
                api: RestApiType.ORDERS,
                id: this.model.id,
                mapper: MapperType.ORDER_FORM,
            })
        },

        onCancelOrder() {
            this.$store.dispatch(`baseForm/${baseFormType.actions.LOAD}`, {
                api: RestApiType.ORDERS,
                id: this.model.id,
                mapper: MapperType.ORDER_FORM,
            })
        },

        getCanteen() {
            if (this.model?.cloudcanteen || this.model?.residentialcanteen) {
                RestApiCollection.get(
                    RestApiType.RESTAURANT_ORDER_CANTEENS
                ).get(
                    getId(this.model.restaurant),
                    this.model?.cloudcanteen?.identifier ||
                        this.model?.residentialcanteen?.identifier
                )
            }
        },

        getLastModifyInfo() {
            const exportParams = {
                limit: 1,
                property: 'status',
            }
            RestApiCollection.get(RestApiType.AUDITLOG)
                .get(this.model.id, 'Ordering', exportParams)
                .then((response) => {
                    const lastModifiedRow = response.slice(0, 1)
                    this.$set(this, 'orderLastModified', {
                        user: lastModifiedRow[0]?.blame_user || 'system',
                        createdAt: this.$date
                            .utc(lastModifiedRow[0].created_at)
                            .local()
                            .format('YYYY-MM-DD HH:mm:ss'),
                    })
                })
        },

        deliverectCancel() {
            RestApiCollection.get(RestApiType.ORDERS)
                .queueDeliverectCancel(this.model.id)
                .then(() => {
                    Notification.success(
                        this.$t('notifications.deliverect_cancel_sent'),
                        this.$t('notifications.success')
                    )
                })
        },
    },
}
</script>

<style scoped lang="scss"></style>
